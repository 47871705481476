import { lazy } from 'react';

// project import=
import Loadable from 'ui-component/Loadable';
import MainLayout from 'layout/MainLayout';

import WithAuthentication from 'components/withAuthentication';
import IfHasAccess from 'components/IfHasAccess';

// const DashboardDefault = Loadable(lazy(() => import('views/dashboard')));
const Data = Loadable(lazy(() => import('views/data')));
const Database = Loadable(lazy(() => import('views/database')));
const DatabaseInfo = Loadable(lazy(() => import('views/databaseInfo')));
const DataType = Loadable(lazy(() => import('views/dataType')));
const Device = Loadable(lazy(() => import('views/device')));
const DeviceInfo = Loadable(lazy(() => import('views/deviceInfo')));
const DeviceType = Loadable(lazy(() => import('views/deviceType')));
const Home = Loadable(lazy(() => import('views/home')));
const Firmware = Loadable(lazy(() => import('views/firmware')));
const GraphSensorInsights = Loadable(lazy(() => import('views/graph/sensorInsights')));
const GraphRaw = Loadable(lazy(() => import('views/graph/raw')));
const Location = Loadable(lazy(() => import('views/location')));
const LocationInfo = Loadable(lazy(() => import('views/locationInfo')));
const LocationType = Loadable(lazy(() => import('views/locationType')));
const MapDevice = Loadable(lazy(() => import('views/map/device')));
const Measurement = Loadable(lazy(() => import('views/measurement')));
const Organization = Loadable(lazy(() => import('views/organization')));
const OrganizationInfo = Loadable(lazy(() => import('views/organizationInfo')));
const Parameter = Loadable(lazy(() => import('views/parameter')));
const Permission = Loadable(lazy(() => import('views/permission')));
const Profile = Loadable(lazy(() => import('views/profile/index')));
const SensorInsights = Loadable(lazy(() => import('views/sensorInsights')));
const Subscriber = Loadable(lazy(() => import('views/subscriber')));
const SubcriberInfo = Loadable(lazy(() => import('views/subscriberInfo')));
const User = Loadable(lazy(() => import('views/user')));

// ==============================|| MAIN ROUTING ||============================== //

const OrgRoutes = {
  path: '/app/:orgID',
  element: (
    <WithAuthentication>
      <MainLayout />
    </WithAuthentication>
  ),
  children: [
    {
      path: 'data',
      element: (
        <IfHasAccess access="member" navigateTo="/login">
          <Data />
        </IfHasAccess>
      ),
    },
    {
      path: 'home',
      element: (
        <IfHasAccess access="member" navigateTo="/login">
          <Home />
        </IfHasAccess>
      ),
    },
    {
      path: 'graph/sensorinsights',
      element: (
        <IfHasAccess access="member" navigateTo="/login">
          <GraphSensorInsights />
        </IfHasAccess>
      ),
    },
    {
      path: 'graph/raw',
      element: (
        <IfHasAccess access="member" navigateTo="/login">
          <GraphRaw />
        </IfHasAccess>
      ),
    },
    {
      path: 'datatypes',
      element: (
        <IfHasAccess access="member" navigateTo="/login">
          <DataType />
        </IfHasAccess>
      ),
    },
    {
      path: 'databases',
      element: (
        <IfHasAccess access="admin" navigateTo="/login">
          <Database />
        </IfHasAccess>
      ),
    },
    {
      path: 'database/:databaseName',
      element: (
        <IfHasAccess access="admin" navigateTo="/login">
          <DatabaseInfo />
        </IfHasAccess>
      ),
    },
    {
      path: 'users',
      element: (
        <IfHasAccess access="admin" navigateTo="/login">
          <User />
        </IfHasAccess>
      ),
    },
    {
      path: 'parameters',
      element: (
        <IfHasAccess access="superuser" navigateTo="/login">
          <Parameter />
        </IfHasAccess>
      ),
    },
    {
      path: 'permissions',
      element: (
        <IfHasAccess access="admin" navigateTo="/login">
          <Permission />
        </IfHasAccess>
      ),
    },
    {
      path: 'firmware',
      element: (
        <IfHasAccess access="superuser" navigateTo="/login">
          <Firmware />
        </IfHasAccess>
      ),
    },
    {
      path: 'map/devices',
      element: (
        <IfHasAccess access="member" navigateTo="/login">
          <MapDevice />
        </IfHasAccess>
      ),
    },
    {
      path: 'measurements',
      element: (
        <IfHasAccess access="member" navigateTo="/login">
          <Measurement />
        </IfHasAccess>
      ),
    },
    {
      path: 'organizations',
      element: (
        <IfHasAccess access="superuser" navigateTo="/login">
          <Organization />
        </IfHasAccess>
      ),
    },
    {
      path: 'organization/:organizationID',
      element: (
        <IfHasAccess access="admin" navigateTo="/login">
          <OrganizationInfo />
        </IfHasAccess>
      ),
    },
    {
      path: 'sensorinsights',
      element: (
        <IfHasAccess access="member" navigateTo="/login">
          <SensorInsights />
        </IfHasAccess>
      ),
    },
    {
      path: 'subscribers',
      element: (
        <IfHasAccess access="admin" navigateTo="/login">
          <Subscriber />
        </IfHasAccess>
      ),
    },
    {
      path: 'subscriber/:subID',
      element: (
        <IfHasAccess access="admin" navigateTo="/login">
          <SubcriberInfo />
        </IfHasAccess>
      ),
    },
    {
      path: 'profile/:userID',
      element: (
        <IfHasAccess access="admin" navigateTo="/login">
          <Profile />
        </IfHasAccess>
      ),
    },
    {
      path: 'devices',
      element: (
        <IfHasAccess access="member" navigateTo="/login">
          <Device />
        </IfHasAccess>
      ),
    },
    {
      path: 'device/:deviceID',
      element: (
        <IfHasAccess access="member" navigateTo="/login">
          <DeviceInfo />
        </IfHasAccess>
      ),
    },
    {
      path: 'devicetypes',
      element: (
        <IfHasAccess access="superuser" navigateTo="/login">
          <DeviceType />
        </IfHasAccess>
      ),
    },
    {
      path: 'locations',
      element: (
        <IfHasAccess access="member" navigateTo="/login">
          <Location />
        </IfHasAccess>
      ),
    },
    {
      path: 'location/:locationID',
      element: (
        <IfHasAccess access="member" navigateTo="/login">
          <LocationInfo />
        </IfHasAccess>
      ),
    },
    {
      path: 'locationtypes',
      element: (
        <IfHasAccess access="superuser" navigateTo="/login">
          <LocationType />
        </IfHasAccess>
      ),
    },
  ],
};

export default OrgRoutes;
