import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

const initialState = {
  loading: false,
  databases: [],
  databaseInfo: [],
  access: [],
  error: null,
  success: false,
  errorMessage: null,
};

export const databaseGet = createAsyncThunk('database/get', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/database';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const databaseGetInfo = createAsyncThunk(
  'database/getInfo',
  async ({ databaseName, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!databaseName) {
        throw new Error('Database name and table name are required');
      }

      let url = '/api/database/info';
      if (databaseName) {
        url += `/${databaseName}`;
      }
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.get(url, config);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const databasePost = createAsyncThunk('database/post', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/database';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.post(url, payload, config);
    return { ...response, payload };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const databasePut = createAsyncThunk('database/put', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/database';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.put(url, payload, config);
    return { ...response, payload };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const databaseAccessGet = createAsyncThunk(
  'database/access/get',
  async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/database/access';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.get(url, config);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const databaseAccessPost = createAsyncThunk(
  'database/access/post',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/database/access';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.post(url, payload, config);
      return { ...response, payload };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const databaseAccessDelete = createAsyncThunk(
  'database/access/delete',
  async ({ databaseName, organization, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!databaseName || !organization) {
        throw new Error('databaseName and organization are required');
      }

      let url = `/api/database/access/${databaseName}/${organization}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.delete(url, config);
      return { ...response, databaseName, organization };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const databaseSlice = createSlice({
  name: 'database',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(databaseGet.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(databaseGet.fulfilled, (state, action) => {
      state.loading = false;
      state.databases = action.payload.data;

      state.databases.sort((a, b) => {
        return a.database_name.localeCompare(b.database_name);
      });
    });

    builder.addCase(databaseGet.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(databaseGetInfo.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(databaseGetInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.databaseInfo = action.payload.data;
    });

    builder.addCase(databaseGetInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(databasePost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(databasePost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const newEntry = action.payload.payload;

      if (newEntry?.name) {
        const existingIndex = state.databases.findIndex((item) => item.database_name === newEntry?.name);
        if (existingIndex === -1) {
          state.databases.push({ database_name: newEntry?.name });
          state.databases.sort((a, b) => {
            return a.database_name.localeCompare(b.database_name);
          });
        }
      }
    });

    builder.addCase(databasePost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(databasePut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(databasePut.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(databasePut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(databaseAccessGet.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(databaseAccessGet.fulfilled, (state, action) => {
      state.loading = false;
      const newEntries = action.payload.data || [];
      newEntries.forEach((entry) => {
        const existingIndex = state.access.findIndex(
          (item) => item.organization === entry.organization && item.database_name === entry.database_name
        );
        if (existingIndex !== -1) {
          state.access[existingIndex] = entry;
        } else {
          state.access.push(entry);
        }
      });
    });

    builder.addCase(databaseAccessGet.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(databaseAccessPost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(databaseAccessPost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      action.payload.payload?.databaseAccesses.forEach((entry) => {
        const existingIndex = state.access.findIndex(
          (item) => item.organization === entry.organization && item.database_name === entry.database_name
        );
        if (existingIndex !== -1) {
          state.access[existingIndex] = entry;
        } else {
          state.access.push(entry);
        }
      });
    });

    builder.addCase(databaseAccessPost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(databaseAccessDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(databaseAccessDelete.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.access = state.access.filter((entry) => {
        return !(entry.database_name === action.payload.databaseName && entry.organization === action.payload.organization);
      });
    });

    builder.addCase(databaseAccessDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });
  },
});

export default databaseSlice.reducer;
