import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import moment from 'moment-timezone';

import axios from 'api';

const initialState = {
  loading: false,
  sensorInsights: [],
  success: false,
  errorMessage: null,
};

export const sensorInsightsGet = createAsyncThunk(
  'sensorInsights/get',
  async ({ databaseName, tableName, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };
    try {
      if (!databaseName && !tableName) {
        throw new Error('Database name and table name are required');
      }

      let url = `/api/sensorinsights/${databaseName}/${tableName}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.get(url, config);
      return {
        ...response,
        databaseName,
        tableName,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sensorInsightsGetLast = createAsyncThunk(
  'sensorInsights/get/last',
  async ({ databaseName, tableName, relativeTime, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };
    try {
      if (!databaseName && !tableName && !relativeTime) {
        throw new Error('Database name, table name and relative time are required');
      }

      let url = `/api/sensorinsights/${databaseName}/${tableName}/last/${relativeTime}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.get(url, config);
      return {
        ...response,
        databaseName,
        tableName,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sensorInsightsGetLatest = createAsyncThunk(
  'sensorInsights/get/lastest',
  async ({ databaseName, tableName, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };
    try {
      if (!databaseName && !tableName) {
        throw new Error('Database name, table name and relative time are required');
      }

      let url = `/api/sensorinsights/${databaseName}/${tableName}/latest`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.get(url, config);
      return {
        ...response,
        databaseName,
        tableName,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const sensorInsightsSlice = createSlice({
  name: 'sensorInsights',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sensorInsightsGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(sensorInsightsGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const newEntries = action.payload.data?.data || [];
      const { databaseName, tableName } = action.payload;

      newEntries.forEach((newEntry) => {
        const modifiedEntry = {
          ...newEntry,
          databaseName,
          tableName,
        };

        const existingIndex = state.sensorInsights.findIndex(
          (entry) =>
            entry.local_time === newEntry.local_time &&
            entry.location_id === newEntry.location_id &&
            entry.device_id === newEntry.device_id &&
            entry.databaseName === databaseName &&
            entry.tableName === tableName
        );

        if (existingIndex !== -1) {
          if (tableName === 'measurement') {
            state.sensorInsights[existingIndex] = modifiedEntry;
          } else {
            state.sensorInsights[existingIndex] = {
              ...state.sensorInsights[existingIndex],
              ...modifiedEntry,
            };
          }
        } else {
          state.sensorInsights.push(modifiedEntry);
        }
      });

      state.sensorInsights.sort((a, b) => {
        return moment(a.local_time, 'YYYY-MM-DD HH:mm:ss').valueOf() - moment(b.local_time, 'YYYY-MM-DD HH:mm:ss').valueOf();
      });
    });

    builder.addCase(sensorInsightsGet.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.sensorInsights = [];
      state.errorMessage = action.payload;
    });

    builder.addCase(sensorInsightsGetLast.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(sensorInsightsGetLast.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const newEntries = action.payload.data?.data || [];
      const { databaseName, tableName } = action.payload;

      newEntries.forEach((newEntry) => {
        const modifiedEntry = {
          ...newEntry,
          databaseName,
          tableName,
        };

        const existingIndex = state.sensorInsights.findIndex(
          (entry) =>
            entry.local_time === newEntry.local_time &&
            entry.location_id === newEntry.location_id &&
            entry.device_id === newEntry.device_id &&
            entry.databaseName === databaseName &&
            entry.tableName === tableName
        );

        if (existingIndex !== -1) {
          if (tableName === 'measurement') {
            state.sensorInsights[existingIndex] = modifiedEntry;
          } else {
            state.sensorInsights[existingIndex] = {
              ...state.sensorInsights[existingIndex],
              ...modifiedEntry,
            };
          }
        } else {
          state.sensorInsights.push(modifiedEntry);
        }
      });

      state.sensorInsights.sort((a, b) => {
        return moment(a.local_time, 'YYYY-MM-DD HH:mm:ss').valueOf() - moment(b.local_time, 'YYYY-MM-DD HH:mm:ss').valueOf();
      });
    });

    builder.addCase(sensorInsightsGetLast.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.sensorInsights = [];
      state.errorMessage = action.payload;
    });

    builder.addCase(sensorInsightsGetLatest.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(sensorInsightsGetLatest.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const newEntries = action.payload.data?.data || [];
      const { databaseName, tableName } = action.payload;

      newEntries.forEach((newEntry) => {
        const modifiedEntry = {
          ...newEntry,
          databaseName,
          tableName,
        };

        const existingIndex = state.sensorInsights.findIndex(
          (entry) =>
            entry.local_time === newEntry.local_time &&
            entry.location_id === newEntry.location_id &&
            entry.device_id === newEntry.device_id &&
            entry.databaseName === databaseName &&
            entry.tableName === tableName
        );

        if (existingIndex !== -1) {
          if (tableName === 'measurement') {
            state.sensorInsights[existingIndex] = modifiedEntry;
          } else {
            state.sensorInsights[existingIndex] = {
              ...state.sensorInsights[existingIndex],
              ...modifiedEntry,
            };
          }
        } else {
          state.sensorInsights.push(modifiedEntry);
        }
      });

      state.sensorInsights.sort((a, b) => {
        return moment(a.local_time, 'YYYY-MM-DD HH:mm:ss').valueOf() - moment(b.local_time, 'YYYY-MM-DD HH:mm:ss').valueOf();
      });
    });

    builder.addCase(sensorInsightsGetLatest.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.sensorInsights = [];
      state.errorMessage = action.payload;
    });
  },
});

export default sensorInsightsSlice.reducer;
