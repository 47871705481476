import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { PropTypes } from 'prop-types';

import Loader from 'ui-component/Loader';

const WithAuthentication = ({ children }) => {
  const { isAuthenticated } = useSelector((state) => state.account);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function checkAuth() {
      const tokenStorage = localStorage.getItem('token') ? localStorage.getItem('token') : null;
      const userStorage = localStorage.getItem('user') ? localStorage.getItem('user') : null;
      const tokenCookies = Cookies.get('token');
      const userCookies = Cookies.get('user');

      if (tokenStorage && userStorage) {
        dispatch({
          type: 'account/login/fulfilled',
          payload: { token: tokenStorage, user: JSON.parse(userStorage) },
        });
      } else if (tokenCookies && userCookies) {
        dispatch({
          type: 'account/login/fulfilled',
          payload: { token: tokenCookies, user: JSON.parse(userCookies) },
        });
      }

      setIsLoading(false);
    }

    checkAuth();
  }, [dispatch]);

  if (isLoading) {
    return <Loader />;
  }
  if (isAuthenticated) {
    return children;
  }
  return <Navigate to="/login" replace />;
};

WithAuthentication.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WithAuthentication;
