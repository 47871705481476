import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import moment from 'moment-timezone';

import axios from 'api';

const initialState = {
  loading: false,
  measurements: [],
  success: false,
  errorMessage: null,
};

export const measurementGet = createAsyncThunk(
  'measurement/get',
  async ({ databaseName, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };
    try {
      if (!databaseName) {
        throw new Error('Database name is required');
      }

      let url = `/api/measurement/${databaseName}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.get(url, config);

      return {
        ...response,
        databaseName,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const measurementGetLast = createAsyncThunk(
  'measurement/get/last',
  async ({ databaseName, relativeTime, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };
    try {
      if (!databaseName && !relativeTime) {
        throw new Error('Database name and relative time are required');
      }

      let url = `/api/measurement/${databaseName}/last/${relativeTime}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.get(url, config);

      return {
        ...response,
        databaseName,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const measurementGetLatest = createAsyncThunk(
  'measurement/get/lastest',
  async ({ databaseName, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };
    try {
      if (!databaseName) {
        throw new Error('Database name and relative time are required');
      }

      let url = `/api/measurement/${databaseName}/latest`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.get(url, config);

      return {
        ...response,
        databaseName,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const measurementSlice = createSlice({
  name: 'measurement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(measurementGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(measurementGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const newEntries = action.payload.data?.data || [];
      const { databaseName } = action.payload;

      newEntries.forEach((newEntry) => {
        const modifiedEntry = {
          ...newEntry,
          databaseName,
          tableName,
        };

        const existingIndex = state.measurements.findIndex(
          (entry) =>
            entry.local_time === newEntry.local_time &&
            entry.source === newEntry.source &&
            entry.type === newEntry.type &&
            entry.topic === newEntry.topic &&
            entry.databaseName === databaseName
        );

        if (existingIndex !== -1) {
          state.measurements[existingIndex] = modifiedEntry;
        } else {
          state.measurements.push(modifiedEntry);
        }
      });

      state.measurements.sort((a, b) => {
        return moment(a.local_time, 'YYYY-MM-DD HH:mm:ss').valueOf() - moment(b.local_time, 'YYYY-MM-DD HH:mm:ss').valueOf();
      });
    });

    builder.addCase(measurementGet.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.measurements = [];
      state.errorMessage = action.payload;
    });

    builder.addCase(measurementGetLast.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(measurementGetLast.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const newEntries = action.payload.data?.data || [];
      const { databaseName } = action.payload;

      newEntries.forEach((newEntry) => {
        const modifiedEntry = {
          ...newEntry,
          databaseName,
          tableName,
        };

        const existingIndex = state.measurements.findIndex(
          (entry) =>
            entry.local_time === newEntry.local_time &&
            entry.source === newEntry.source &&
            entry.type === newEntry.type &&
            entry.topic === newEntry.topic &&
            entry.databaseName === databaseName
        );

        if (existingIndex !== -1) {
          state.measurements[existingIndex] = modifiedEntry;
        } else {
          state.measurements.push(modifiedEntry);
        }
      });

      state.measurements.sort((a, b) => {
        return moment(a.local_time, 'YYYY-MM-DD HH:mm:ss').valueOf() - moment(b.local_time, 'YYYY-MM-DD HH:mm:ss').valueOf();
      });
    });

    builder.addCase(measurementGetLast.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.measurements = [];
      state.errorMessage = action.payload;
    });

    builder.addCase(measurementGetLatest.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(measurementGetLatest.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const newEntries = action.payload.data || [];
      const { databaseName } = action.payload;

      newEntries.forEach((newEntry) => {
        const modifiedEntry = {
          ...newEntry,
          databaseName,
          tableName,
        };

        const existingIndex = state.measurements.findIndex(
          (entry) =>
            entry.local_time === newEntry.local_time &&
            entry.source === newEntry.source &&
            entry.type === newEntry.type &&
            entry.topic === newEntry.topic &&
            entry.databaseName === databaseName
        );

        if (existingIndex !== -1) {
          state.measurements[existingIndex] = modifiedEntry;
        } else {
          state.measurements.push(modifiedEntry);
        }
      });

      state.measurements.sort((a, b) => {
        return moment(a.local_time, 'YYYY-MM-DD HH:mm:ss').valueOf() - moment(b.local_time, 'YYYY-MM-DD HH:mm:ss').valueOf();
      });
    });

    builder.addCase(measurementGetLatest.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.measurements = [];
      state.errorMessage = action.payload;
    });
  },
});

export default measurementSlice.reducer;
