// getMenuItemsData.js
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// project imports
import dashboard from './dashboard';
import platform from './platform';
import { userRolesFlatten } from 'utils/flattenArray';

export default function useMenuItemsData() {
  const { databaseName, deviceID, locationID, organizationID, orgID, subID, userID } = useParams();
  const { account } = useSelector((state) => state.account);
  const { databases } = useSelector((state) => state.database);
  const { devices } = useSelector((state) => state.device);
  const { locations } = useSelector((state) => state.location);
  const { organizations } = useSelector((state) => state.organization);
  const { subscribers } = useSelector((state) => state.subscriber);
  const { users } = useSelector((state) => state.user);
  const { userRoles } = useSelector((state) => state.userRole);

  const usersDisplay = useMemo(() => {
    const usersWithOrgRoles = users.map((user) => {
      const userRole = userRoles.find((userRole) => userRole?.user?.user_id === user.user_id);
      return {
        ...user,
        orgRoles: userRole?.organizations || [],
      };
    });

    return orgID === 'superuser' ? usersWithOrgRoles : userRolesFlatten(userRoles);
  }, [orgID, userRoles, users]);

  const database = databases.find((database) => database.database_name === databaseName) || null;
  const device = devices.find((device) => device.device_id === deviceID, 10) || null;
  const location = locations.find((location) => location.location_id === locationID) || null;
  const organization = organizations.find((organization) => organization.organization === organizationID) || null;
  const subscriber = subID ? subscribers.find((subscriber) => subscriber.sub_id === parseInt(subID, 10)) || { sub_id: subID } : null;
  const user = userID ? usersDisplay.find((user) => user.user_id === parseInt(userID, 10)) || { user_id: userID } : null;
  const items = [dashboard({ orgID })];

  const role = account?.role === 'superuser' ? 'superuser' : account.organizations.find(({ organization }) => organization === orgID)?.role;

  items.push(
    platform({
      database,
      device,
      location,
      organization,
      orgID,
      role,
      subscriber,
      user,
    })
  );
  return { items };
}
