import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

const initialState = {
  loading: false,
  organizations: [],
  configurations: [],
  success: false,
  error: null,
};

export const organizationGet = createAsyncThunk('organization/get', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };
  try {
    let url = '/api/organization';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const organizationPost = createAsyncThunk(
  'organization/post',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/organization';
      if (urlParam) {
        url += `/${urlParam}`;
      }

      return await axios.post(url, payload, config).then((response) => ({
        data: response.data,
        payload,
      }));
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const organizationPut = createAsyncThunk(
  'organization/put',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/organization';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.put(url, payload, config).then((response) => ({
        data: response.data,
        payload,
      }));
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const organizationStatusPut = createAsyncThunk(
  'organization/status/put',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/organization/status';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.put(url, payload, config).then((response) => ({
        data: response.data,
        payload,
      }));
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const organizationConfigurationGet = createAsyncThunk(
  'organization/configuration/get',
  async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };
    try {
      let url = '/api/organization/configuration';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.get(url, config);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const organizationConfigurationPost = createAsyncThunk(
  'organization/configuration/post',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/organization/configuration';
      if (urlParam) {
        url += `/${urlParam}`;
      }

      return await axios.post(url, payload, config).then((response) => ({
        data: response.data,
        payload,
      }));
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const organizationConfigurationPut = createAsyncThunk(
  'organization/configuration/put',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/organization/configuration';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.put(url, payload, config).then((response) => ({
        data: response.data,
        payload,
      }));
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(organizationGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(organizationGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.organizations = action.payload.data;
    });

    builder.addCase(organizationGet.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.error.message;
    });

    builder.addCase(organizationPost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(organizationPost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      action.payload.payload?.organizations.forEach((organization) => {
        state.organizations.push({
          ...organization,
          status: 'DISABLED',
        });
      });

      state.organizations.sort((a, b) => a.description.localeCompare(b.description));
    });

    builder.addCase(organizationPost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(organizationPut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(organizationPut.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      action.payload.payload?.organizations.forEach((organization) => {
        const index = state.organizations.findIndex((org) => org.organization === organization.organization);
        if (index !== -1) {
          state.organizations[index] = organization;
        }
      });
    });

    builder.addCase(organizationPut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(organizationStatusPut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(organizationStatusPut.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      action.payload.payload?.organizationStatus.forEach((status) => {
        const index = state.organizations.findIndex((org) => org.organization === status.organization);
        if (index !== -1) {
          state.organizations[index] = {
            ...state.organizations[index],
            status: status.status,
          };
        }
      });
    });

    builder.addCase(organizationStatusPut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(organizationConfigurationPost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(organizationConfigurationPost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      action.payload.payload?.organizationConfigurations.forEach((config) => {
        state.configurations.push(config);
      });

      state.configurations.sort((a, b) => a.organization.localeCompare(b.organization));
    });

    builder.addCase(organizationConfigurationGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(organizationConfigurationGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      action.payload.data.map((config) => {
        const index = state.configurations.findIndex((cfg) => cfg.organization === config.organization);
        if (index !== -1) {
          state.configurations[index] = config;
        } else {
          state.configurations.push(config);
        }
      });

      state.configurations.sort((a, b) => a.organization.localeCompare(b.organization));
    });

    builder.addCase(organizationConfigurationGet.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.error.message;
    });

    builder.addCase(organizationConfigurationPost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(organizationConfigurationPut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(organizationConfigurationPut.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      action.payload.payload?.organizationConfigurations.forEach((config) => {
        const index = state.configurations.findIndex((cfg) => cfg.organization === config.organization);
        if (index !== -1) {
          state.configurations[index] = config;
        }
      });
    });

    builder.addCase(organizationConfigurationPut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });
  },
});

export default organizationSlice.reducer;
