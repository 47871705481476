import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

const initialState = {
  loading: false,
  devices: [],
  types: [],
  success: false,
  error: null,
};

export const deviceGet = createAsyncThunk('device/get', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };
  try {
    let url = '/api/device';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const devicePost = createAsyncThunk('device/post', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/device';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    return await axios.post(url, payload, config).then((response) => ({
      data: response.data,
      payload,
    }));
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const devicePut = createAsyncThunk('device/put', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/device';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    return await axios.put(url, payload, config).then((response) => ({
      data: response.data,
      payload,
    }));
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deviceDelete = createAsyncThunk(
  'device/delete',
  async ({ deviceID, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!deviceID) {
        throw new Error('Device ID is required');
      }

      let url = `/api/device/${deviceID}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }

      return await axios.delete(url, config).then((response) => ({
        data: response?.data,
        deviceID,
      }));
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deviceTypeGet = createAsyncThunk('device/get/type', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };
  try {
    let url = '/api/device/type';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deviceTypePost = createAsyncThunk(
  'device/post/type',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/device/type';
      if (urlParam) {
        url += `/${urlParam}`;
      }

      return await axios.post(url, payload, config).then((response) => ({
        data: response.data,
        payload,
      }));
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deviceTypePut = createAsyncThunk(
  'device/put/type',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/device/type';
      if (urlParam) {
        url += `/${urlParam}`;
      }

      return await axios.put(url, payload, config).then((response) => ({
        data: response.data,
        payload,
      }));
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deviceTypeDelete = createAsyncThunk(
  'device/delete/type',
  async ({ deviceType, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!deviceType) {
        throw new Error('Device type is required');
      }

      let url = `/api/device/type/${deviceType}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.delete(url, config).then((response) => ({
        data: response?.data,
        deviceType,
      }));
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deviceMappingPost = createAsyncThunk(
  'device/post/mapping',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/device/mapping';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.post(url, payload, config);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deviceMappingDelete = createAsyncThunk(
  'device/delete/mapping',
  async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/device/mapping';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.delete(url, config);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(deviceGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deviceGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const newEntries = action.payload.data || [];

      newEntries.forEach((newEntry) => {
        const existingIndex = state.devices.findIndex((entry) => entry.device_id === newEntry.device_id);
        if (existingIndex !== -1) {
          state.devices[existingIndex] = newEntry;
        } else {
          state.devices.push(newEntry);
        }
      });

      state.devices.sort((a, b) => a.device_name.localeCompare(b.device_name));
    });

    builder.addCase(deviceGet.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.devices = [];
      state.errorMessage = action.payload;
    });

    builder.addCase(devicePost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(devicePost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      action.payload.payload?.devices.forEach((device) => {
        state.devices.push(device);
      });
    });

    builder.addCase(devicePost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(devicePut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(devicePut.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      action.payload.payload?.devices.forEach((device) => {
        const index = state.devices.findIndex((entry) => entry.device_id === device.device_id);
        if (index !== -1) {
          state.devices[index] = device;
        }
      });
    });

    builder.addCase(devicePut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(deviceDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(deviceDelete.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      state.devices = state.devices.filter((entry) => entry.device_id !== action.payload.deviceID);
    });

    builder.addCase(deviceDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(deviceTypeGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deviceTypeGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const newEntries = action.payload.data?.data || [];

      newEntries.forEach((newEntry) => {
        const existingIndex = state.types.findIndex((entry) => entry.device_type === newEntry.device_type);
        if (existingIndex !== -1) {
          state.types[existingIndex] = newEntry;
        } else {
          state.types.push(newEntry);
        }
      });

      state.types.sort((a, b) => a.device_type.localeCompare(b.device_type));
    });

    builder.addCase(deviceTypeGet.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.types = [];
      state.errorMessage = action.payload;
    });

    builder.addCase(deviceTypePost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(deviceTypePost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      action.payload.payload?.deviceTypes.forEach((type) => {
        state.types.push(type);
      });
    });

    builder.addCase(deviceTypePost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(deviceTypePut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(deviceTypePut.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      action.payload.payload?.deviceTypes.forEach((type) => {
        const index = state.types.findIndex((entry) => entry.device_type === type.device_type);
        if (index !== -1) {
          state.types[index] = type;
        }
      });
    });

    builder.addCase(deviceTypePut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(deviceTypeDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(deviceTypeDelete.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.types = state.types.filter((entry) => entry.device_type !== action.payload.deviceType);
    });

    builder.addCase(deviceTypeDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(deviceMappingPost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(deviceMappingPost.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(deviceMappingPost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(deviceMappingDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(deviceMappingDelete.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(deviceMappingDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });
  },
});

export default deviceSlice.reducer;
