// material-ui
import { useTheme } from '@mui/material/styles';

import logo from 'assets/images/logo.webp';
import logoDark from 'assets/images/logo_dark.webp';

const Logo = () => {
  const theme = useTheme();

  if (theme.palette.mode === 'dark') {
    return <img src={logoDark} alt="UP CARE" width="125" />;
  } else {
    return <img src={logo} alt="UP CARE" width="125" />;
  }
};

export default Logo;
