import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// material-ui
import { styled } from '@mui/material/styles';
import { Avatar, Card, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';

// project imports
import BackgroundLetterAvatar from 'ui-component/BackgroundLetterAvatar';

//assets
import { IconHomeShield } from '@tabler/icons-react';

// styles
const CardStyle = styled(Card)(({ theme }) => ({
  background: theme.palette.warning.light,
  marginTop: '16px',
  // marginBottom: '16px',
  overflow: 'hidden',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: '200px',
    height: '200px',
    border: '19px solid ',
    borderColor: theme.palette.warning.main,
    borderRadius: '50%',
    top: '15px',
    right: '-150px',
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    width: '200px',
    height: '200px',
    border: '3px solid ',
    borderColor: theme.palette.warning.main,
    borderRadius: '50%',
    top: '85px',
    right: '-70px',
  },
}));

// ==============================|| PROFILE MENU - UPGRADE PLAN CARD ||============================== //

const CurrentOrgCard = () => {
  const customization = useSelector((state) => state.customization);
  const { account } = useSelector((state) => state.account);
  const { orgID } = useParams();

  const { description, role } = account.organizations.find(({ organization }) => organization === orgID) || {
    description: 'Organization',
  };

  return (
    <CardStyle>
      <List>
        <ListItem
          sx={{
            borderRadius: `${customization.borderRadius}px`,
            backgroundColor: 'inherit',
          }}
        >
          <ListItemAvatar>
            {orgID === 'superuser' ? (
              <Avatar>
                <IconHomeShield />
              </Avatar>
            ) : (
              <BackgroundLetterAvatar name={description} />
            )}
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant="body1" color="inherit">
                {orgID === 'superuser' ? 'Platform Management Dashboard' : description}
              </Typography>
            }
            secondary={
              <Typography variant="caption" color="inherit" sx={{ textTransform: 'capitalize' }}>
                {account.role === 'superuser' ? 'Super User' : role}
              </Typography>
            }
          />
        </ListItem>
      </List>
    </CardStyle>
  );
};

export default CurrentOrgCard;
