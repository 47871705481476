import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import WithoutAuthentication from '../components/withoutAuthentication';

// login option routing
const AuthConfirm = Loadable(lazy(() => import('views/pages/authentication/authentication/Confirm')));
const AuthForgot = Loadable(lazy(() => import('views/pages/authentication/authentication/Forgot')));
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication/Register')));
const AuthResend = Loadable(lazy(() => import('views/pages/authentication/authentication/Resend')));
const AuthReset = Loadable(lazy(() => import('views/pages/authentication/authentication/Reset')));

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: 'confirm',
      element: (
        <WithoutAuthentication>
          <AuthConfirm />
        </WithoutAuthentication>
      ),
    },
    {
      path: 'forgot',
      element: (
        <WithoutAuthentication>
          <AuthForgot />
        </WithoutAuthentication>
      ),
    },
    {
      path: 'login',
      element: (
        <WithoutAuthentication>
          <AuthLogin />
        </WithoutAuthentication>
      ),
    },
    {
      path: 'register',
      element: (
        <WithoutAuthentication>
          <AuthRegister />
        </WithoutAuthentication>
      ),
    },
    {
      path: 'resend',
      element: (
        <WithoutAuthentication>
          <AuthResend />
        </WithoutAuthentication>
      ),
    },
    {
      path: 'reset',
      element: (
        <WithoutAuthentication>
          <AuthReset />
        </WithoutAuthentication>
      ),
    },
  ],
};

export default AuthenticationRoutes;
