import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import PropTypes from 'prop-types';

const WithoutAuthentication = ({ children }) => {
  const { isAuthenticated } = useSelector((state) => state.account);

  return isAuthenticated ? <Navigate to="/" replace /> : children;
};

WithoutAuthentication.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WithoutAuthentication;
