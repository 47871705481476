import { Navigate, useParams } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import useUserRole from 'hooks/useUserRole';
import validateAccess from 'utils/validateAccess';

const IfHasAccess = ({ access, children, navigateTo }) => {
  const { orgID } = useParams();

  const role = useUserRole(orgID);
  const hasAccess = validateAccess({ access, role });

  // Render children if access is granted, otherwise handle navigation or return null
  if (hasAccess) {
    return children;
  } else if (navigateTo === null) {
    return null;
  } else {
    return <Navigate to={navigateTo} replace />;
  }
};

IfHasAccess.propTypes = {
  access: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  navigateTo: PropTypes.string,
};

IfHasAccess.defaultProps = {
  navigateTo: null,
};

export default IfHasAccess;
