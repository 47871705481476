import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

const initialState = {
  loading: false,
  dashboards: [],
  error: null,
  success: false,
  errorMessage: null,
};

export const dashboardGet = createAsyncThunk('dashboard/get', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };
  try {
    let url = '/api/dashboard';

    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const dashboardPost = createAsyncThunk(
  'dashboard/post',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/dashboard';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.post(url, payload, config);
      return {
        data: response?.data,
        payload: payload.dashboards,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const dashboardPut = createAsyncThunk('dashboard/put', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/dashboard';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    return await axios.put(url, payload, config).then((response) => ({
      data: response?.data,
      payload,
    }));
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const dashboardDelete = createAsyncThunk(
  'dashboard/delete',
  async ({ dashboardID, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!dashboardID) {
        throw new Error('Parameter is required');
      }

      let url = `/api/dashboard/${dashboardID}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }

      return await axios.delete(url, config).then((response) => ({
        data: response?.data,
        dashboardID,
      }));
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(dashboardGet.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(dashboardGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const newEntries = action.payload.data?.data || [];

      newEntries.forEach((newEntry) => {
        const existingIndex = state.dashboards.findIndex((entry) => entry.dashboard_id === newEntry.dashboard_id);
        if (existingIndex !== -1) {
          state.dashboards[existingIndex] = newEntry;
        } else {
          state.dashboards.push(newEntry);
        }
      });

      state.dashboards.sort((a, b) => a.dashboard.localeCompare(b.dashboard));
    });

    builder.addCase(dashboardGet.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(dashboardPost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(dashboardPost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const insertedIds = action.payload.data.insertedIds;

      const newDashboards = action.payload.payload.map((dashboard, index) => ({
        ...dashboard,
        dashboard_id: insertedIds[index],
      }));

      state.dashboards.push(...newDashboards);
    });

    builder.addCase(dashboardPost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(dashboardPut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(dashboardPut.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      action.payload.payload?.dashboards.forEach((dashboard) => {
        const index = state.dashboards.findIndex((entry) => entry.dashboard_id === dashboard.dashboard_id);
        if (index !== -1) {
          state.dashboards[index] = dashboard;
        }
      });
    });

    builder.addCase(dashboardPut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(dashboardDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(dashboardDelete.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.dashboards = state.dashboards.filter((dashboard) => dashboard.dashboard_id !== action.payload.dashboardID);
    });

    builder.addCase(dashboardDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });
  },
});

export default dashboardSlice.reducer;
