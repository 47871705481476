import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';

import { SET_DARK_MODE } from 'store/actions';

// assets
import { IconBrightnessUp, IconMoon } from '@tabler/icons-react';

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const darkMode = useSelector((state) => state.customization.darkMode);

  const handleDarkMode = () => {
    dispatch({ type: SET_DARK_MODE, darkMode: !darkMode });
  };

  return (
    <>
      <Box
        sx={{
          ml: 2,
          mr: 3,
          [theme.breakpoints.down('md')]: {
            mr: 2,
          },
        }}
      >
        <ButtonBase sx={{ borderRadius: '12px' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              '&[aria-controls="menu-list-grow"],&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light,
              },
            }}
            onClick={handleDarkMode}
            color="inherit"
          >
            {darkMode ? <IconMoon stroke={1.5} size="1.3rem" /> : <IconBrightnessUp stroke={1.5} size="1.3rem" />}
          </Avatar>
        </ButtonBase>
      </Box>
    </>
  );
};

export default NotificationSection;
