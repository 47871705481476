import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

const initialState = {
  loading: false,
  locations: [],
  types: [],
  success: false,
  error: null,
  errorMessage: null,
};

export const locationGet = createAsyncThunk('location/get', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };
  try {
    let url = '/api/location';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const locationPost = createAsyncThunk('location/post', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/location';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    return await axios.post(url, payload, config).then((response) => ({
      data: response.data,
      payload,
    }));
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const locationPut = createAsyncThunk('location/put', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/location';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    return await axios.put(url, payload, config).then((response) => ({
      data: response.data,
      payload,
    }));
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const locationDelete = createAsyncThunk(
  'location/delete',
  async ({ locationID, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!locationID) {
        throw new Error('Location ID is required');
      }

      let url = `/api/location/${locationID}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.delete(url, config).then((response) => ({
        data: response?.data,
        locationID,
      }));
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const locationTypeGet = createAsyncThunk('location/get/type', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };
  try {
    let url = '/api/location/type';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const locationTypePost = createAsyncThunk(
  'location/post/type',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/location/type';
      if (urlParam) {
        url += `/${urlParam}`;
      }

      return await axios.post(url, payload, config).then((response) => ({
        data: response.data,
        payload,
      }));
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const locationTypePut = createAsyncThunk(
  'location/put/type',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/location/type';
      if (urlParam) {
        url += `/${urlParam}`;
      }

      return await axios.put(url, payload, config).then((response) => ({
        data: response.data,
        payload,
      }));
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const locationTypeDelete = createAsyncThunk(
  'location/delete/type',
  async ({ locationType, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!locationType) {
        throw new Error('Location type is required');
      }

      let url = `/api/location/type/${locationType}`;
      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.delete(url, config).then((response) => ({
        data: response?.data,
        locationType,
      }));
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(locationGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(locationGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const newEntries = action.payload.data || [];

      newEntries.forEach((newEntry) => {
        const existingIndex = state.locations.findIndex((entry) => entry.location_id === newEntry.location_id);
        if (existingIndex !== -1) {
          state.locations[existingIndex] = newEntry;
        } else {
          state.locations.push(newEntry);
        }
      });

      state.locations.sort((a, b) => a.location_id.localeCompare(b.location_id));
    });

    builder.addCase(locationGet.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.locations = [];
      state.errorMessage = action.payload;
    });

    builder.addCase(locationPost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(locationPost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      action.payload.payload?.locations.forEach((location) => {
        state.locations.push(location);
      });
    });

    builder.addCase(locationPost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(locationPut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(locationPut.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      action.payload.payload?.locations.forEach((location) => {
        const index = state.locations.findIndex((entry) => entry.location_id === location.location_id);
        if (index !== -1) {
          state.locations[index] = location;
        }
      });
    });

    builder.addCase(locationPut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(locationDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(locationDelete.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.locations = state.locations.filter((entry) => entry.location_id !== action.payload.locationID);
    });

    builder.addCase(locationDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(locationTypeGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(locationTypeGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const newEntries = action.payload.data?.data || [];

      newEntries.forEach((newEntry) => {
        const existingIndex = state.types.findIndex((entry) => entry.location_type === newEntry.location_type);
        if (existingIndex !== -1) {
          state.types[existingIndex] = newEntry;
        } else {
          state.types.push(newEntry);
        }
      });

      state.types.sort((a, b) => a.location_type.localeCompare(b.location_type));
    });

    builder.addCase(locationTypeGet.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.types = [];
      state.errorMessage = action.payload;
    });

    builder.addCase(locationTypePost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(locationTypePost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      action.payload.payload?.locationTypes.forEach((type) => {
        state.types.push(type);
      });
    });

    builder.addCase(locationTypePost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(locationTypePut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(locationTypePut.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      action.payload.payload?.locationTypes.forEach((type) => {
        const index = state.types.findIndex((entry) => entry.location_type === type.location_type);
        if (index !== -1) {
          state.types[index] = type;
        }
      });
    });

    builder.addCase(locationTypePut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(locationTypeDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(locationTypeDelete.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.types = state.types.filter((entry) => entry.location_type !== action.payload.locationType);
    });

    builder.addCase(locationTypeDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });
  },
});

export default locationSlice.reducer;
