// project imports
import config from 'config';

// action - state management
import * as actionTypes from './actions';

const darkMode = localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : null;

export const initialState = {
  isOpen: [], // for active default menu
  defaultId: 'default',
  fontFamily: config.fontFamily,
  borderRadius: config.borderRadius,
  opened: true,
  darkMode: darkMode,
  prefersDarkMode: false,
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, action) => {
  let id;
  switch (action.type) {
    case actionTypes.MENU_OPEN:
      id = action.id;
      return {
        ...state,
        isOpen: [id],
      };
    case actionTypes.SET_MENU:
      return {
        ...state,
        opened: action.opened,
      };
    case actionTypes.SET_FONT_FAMILY:
      return {
        ...state,
        fontFamily: action.fontFamily,
      };
    case actionTypes.SET_BORDER_RADIUS:
      return {
        ...state,
        borderRadius: action.borderRadius,
      };
    case actionTypes.SET_DARK_MODE:
      localStorage.setItem('darkMode', JSON.stringify(action.darkMode));
      return {
        ...state,
        darkMode: action.darkMode,
      };
    case actionTypes.PREFERS_DARK_MODE:
      return {
        ...state,
        prefersDarkMode: action.prefersDarkMode,
      };
    default:
      return state;
  }
};

export default customizationReducer;
