import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

const initialState = {
  loading: false,
  parameters: [],
  error: null,
  success: false,
  errorMessage: null,
};

export const parameterGet = createAsyncThunk('parameter/get', async ({ urlParam, queryParams }, { rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: queryParams,
  };
  try {
    let url = '/api/parameter';

    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const parameterPost = createAsyncThunk(
  'parameter/post',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/parameter';

      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.post(url, payload, config).then((response) => ({
        data: response?.data,
        payload,
      }));
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const parameterPut = createAsyncThunk('parameter/put', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/parameter';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    return await axios.put(url, payload, config).then((response) => ({
      data: response?.data,
      payload,
    }));
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const parameterDelete = createAsyncThunk(
  'parameter/delete',
  async ({ tableName, parameter, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!tableName) {
        throw new Error('Table name is required');
      }

      if (!parameter) {
        throw new Error('Parameter is required');
      }

      let url = `/api/parameter/${tableName}/${parameter}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }

      return await axios.delete(url, config).then((response) => ({
        data: response?.data,
        tableName,
        parameter,
      }));
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const parameterSlice = createSlice({
  name: 'parameter',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(parameterGet.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(parameterGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const newEntries = action.payload.data?.data || [];

      newEntries.forEach((newEntry) => {
        const existingIndex = state.parameters.findIndex(
          (entry) => entry.parameter === newEntry.parameter && entry.table_name === newEntry.table_name
        );
        if (existingIndex !== -1) {
          state.parameters[existingIndex] = newEntry;
        } else {
          state.parameters.push(newEntry);
        }
      });

      state.parameters.sort((a, b) => a.parameter.localeCompare(b.parameter));
    });

    builder.addCase(parameterGet.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(parameterPost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(parameterPost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      action.payload.payload?.parameters.forEach((parameter) => {
        state.parameters.push(parameter);
      });
    });

    builder.addCase(parameterPost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(parameterPut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(parameterPut.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      action.payload.payload?.parameters.forEach((parameter) => {
        const index = state.parameters.findIndex(
          (entry) => entry.parameter === parameter.parameter && entry.table_name === parameter.table_name
        );
        if (index !== -1) {
          state.parameters[index] = parameter;
        }
      });
    });

    builder.addCase(parameterPut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(parameterDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(parameterDelete.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.parameters = state.parameters.filter(
        (parameter) => parameter.parameter !== action.payload.parameter && parameter.table_name !== action.payload.tableName
      );
    });

    builder.addCase(parameterDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });
  },
});

export default parameterSlice.reducer;
