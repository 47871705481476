import { IconChartArea, IconChartBar, IconChartHistogram, IconHome, IconMap, IconMapCode } from '@tabler/icons-react';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = ({ orgID }) => ({
  id: 'group-home',
  title: '',
  type: 'group',
  children: [
    {
      id: 'home',
      title: 'Home',
      type: 'item',
      url: `/app/${orgID}/home`,
      icon: IconHome,
      breadcrumbs: false,
    },
    {
      id: 'graph-group',
      title: 'Graphs',
      type: 'collapse',
      icon: IconChartHistogram,
      children: [
        {
          id: 'graph-raw',
          title: 'Raw',
          type: 'item',
          url: `/app/${orgID}/graph/raw`,
          icon: IconChartArea,
          breadcrumbs: true,
        },
        {
          id: 'graph-sensorinsights',
          title: 'Sensor Insights',
          type: 'item',
          url: `/app/${orgID}/graph/sensorinsights`,
          icon: IconChartBar,
          breadcrumbs: true,
        },
      ],
    },
    {
      id: 'map-group',
      title: 'Maps',
      type: 'collapse',
      icon: IconMap,
      children: [
        {
          id: 'map-devices',
          title: 'Map (Devices)',
          type: 'item',
          url: `/app/${orgID}/map/devices`,
          icon: IconMapCode,
          breadcrumbs: false,
        },
        // {
        //   id: 'map-locations',
        //   title: 'Map (Locations)',
        //   type: 'item',
        //   url: `/app/${orgID}/map/locations`,
        //   icon: IconMapPin2,
        //   breadcrumbs: true,
        // },
      ],
    },
  ],
});

export default dashboard;
