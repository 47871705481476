import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

const initialState = {
  loading: false,
  firmwares: {
    items: [],
  },
  success: false,
  errorMessage: null,
};

export const firmwareGet = createAsyncThunk('firmware/get', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      // Access the token from the account slice using getState
      Authorization: getState().account.token,
    },
    params: queryParams,
  };
  try {
    let url = '/api/firmware';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data); // Return the error response data as the payload
  }
});

const firmwareSlice = createSlice({
  name: 'firmware',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(firmwareGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(firmwareGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.firmwares = action.payload.data;
    });

    builder.addCase(firmwareGet.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.errorMessage = action.payload;
    });
  },
});

export default firmwareSlice.reducer;
