import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';

// Function to convert a color string to RGB values
const hexToRgb = (hex) => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return [r, g, b];
};

// Function to calculate the luminance of an RGB color
const luminance = (r, g, b) => {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

// Function to determine if the color is dark
const isDark = (color) => {
  const [r, g, b] = hexToRgb(color);
  return luminance(r, g, b) < 0.5;
};

// Function to get a contrasting text color
export const getContrastingTextColor = (bgColor) => (isDark(bgColor) ? '#FFFFFF' : '#000000');

export const stringToColor = (string) => {
  let hash = 0;
  let i;

  if (!string) {
    return '#000000';
  }
  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const stringAvatar = (name, sx = {}) => {
  const bgcolor = stringToColor(name);
  const textColor = getContrastingTextColor(bgcolor);

  if (!name) {
    return {
      sx: {
        bgcolor,
        color: textColor,
        ...sx,
      },
      children: '',
    };
  }

  const words = name.split(' ');

  let children = '';
  if (words.length > 0) {
    children = words[0][0]; // Use the first letter of the first word
    if (words.length > 1) {
      children += words[1][0]; // Append the first letter of the second word if available
    }
  }

  // Default fontSize
  let fontSize = '1rem';

  // Adjust fontSize based on width and height
  if (sx.width && sx.height) {
    const width = parseFloat(sx.width);
    const height = parseFloat(sx.height);
    const size = Math.min(width, height);

    // Set fontSize proportional to the size
    fontSize = `${size * 0.5}px`;
  }

  return {
    sx: {
      ...sx,
      bgcolor,
      color: textColor,
      fontSize,
    },
    children,
  };
};

const BackgroundLetterAvatar = (props) => {
  const { name, sx, ...avatarprops } = props;

  return <Avatar {...stringAvatar(name, sx)} {...avatarprops} />;
};

BackgroundLetterAvatar.propTypes = {
  name: PropTypes.string,
  sx: PropTypes.object,
};

export default BackgroundLetterAvatar;
