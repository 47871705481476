const validateAccess = ({ access, role }) => {
  return (
    role === 'superuser' ||
    access === 'all' ||
    (access === 'admin' && role === 'admin') ||
    (access === 'member' && (role === 'admin' || role === 'member'))
  );
};

export default validateAccess;
